<template>
  <div class="auth-container d-flex align-center justify-center"
    v-bind:style="{background: bgColorCode}">

    <v-card outlined style="width: 80%;">
      <!-- LOGO image row -->
      <v-row>
        <v-col cols="12" md="6" offset-md="3"
          class="d-flex align-item-center">
          <v-img contain :src="logoPath"></v-img>
        </v-col>
      </v-row>
      
      
      <!-- <div class="body-2 grey--text text-center">快一點接單介面 v{{ version }}</div> -->

      <v-form v-model="formLogin.valid">
        <v-container>
          <v-row class="px-4">
            <v-col cols="12">
              <div class="d-flex align-center justify-center">
                <span class="body-2 grey--text text-center" @click="cheatsActivationClicker">
                  {{ appTitle }} v{{ version }}&nbsp;&nbsp;
                </span>
                <v-icon class="mx-1" @click="forceReload">mdi-refresh</v-icon>
                <v-icon class="mx-1" @click="showQrCode">mdi-qrcode</v-icon>
                <v-badge overlap color="pink lighten-1" icon="mdi-bell-ring" :value="showVersionTip">
                  <v-icon class="mx-1" @click="showVersionInfo">mdi-information-outline</v-icon>
                </v-badge>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field label="使用者名稱" outlined dense hide-details
                v-model="formLogin.username" required>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field type="password" label="密碼" outlined dense hide-details
                v-model="formLogin.password" required>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex align-center justify-center">
              <v-radio-group v-model="uiMode" row>
                <v-radio label="手機" value="m"></v-radio>
                <v-radio label="平板／電腦" value="t"></v-radio>
              </v-radio-group>
            </v-col>
            
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-btn block x-large text color="info"
          :disabled="isProcessing" @click="signin">登入</v-btn>
      </v-card-actions>

      <v-progress-linear indeterminate color="teal" v-show="isProcessing"></v-progress-linear>
    </v-card>

    <!-- Dialog - show QR code -->
    <v-dialog v-model="dialog.qrcode.show" width="320px">
      <v-card>
        <v-card-title class="headline">QR Code</v-card-title>
        <v-card-text>
          如需以其它裝置使用此接單介面，可掃描下方二維碼。
        </v-card-text>

        <div class="d-flex justify-center">
          <qrcode :value="dialog.qrcode.url"></qrcode>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog - show QR code -->
    <v-dialog v-model="dialog.versionInfo.show" width="320px">
      <v-card>
        <v-card-title class="headline">版本 v{{version}} 資訊</v-card-title>
        <v-card-text>
          <div v-for="desc in versionChanges" v-bind:key="desc">
            - {{ desc }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- WARNING: Dialog - Cheats -->
    <v-dialog v-model="dialog.cheats.show" @click:outside="submitCheatSettings" width="480px">
      <v-card>
        <v-card-title class="headline">Debug Settings</v-card-title>
        <v-card-text>

          <v-menu ref="menu-picker" v-model="dialog.cheats.picker.show" :close-on-content-click="false"
            transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined label="自訂訂單同步區間"
                v-model="dateRangeText"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="dialog.cheats.dates" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog.cheats.picker.show = false">OK</v-btn>
            </v-date-picker>
          </v-menu>

          <v-checkbox v-model="dialog.cheats.preSyncDisabled" label="關閉登入後Firestore預同步處理"></v-checkbox>
          <v-checkbox v-model="dialog.cheats.submitLogsEnabled" label="登出後提交Console Logs"></v-checkbox>

        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

import axios from 'axios';
import moment from 'moment';
import { fauth } from '../firebase';

export default {
  components: {
    [VueQrcode.name]: VueQrcode
  },

  mounted() {
    this.$store.dispatch('checkForUpdate')
    .then(latestVersion => {
      if (!latestVersion) return;

      //FIXME: Force a page reload if there's a newer remote version.
      //window.location.reload();
      alert(`檢查到有更新的版本（${latestVersion}），重新整理頁面可更新。`);
    });
  },

  methods: {
    signin() {
      if (!this.formLogin.username.length || !this.formLogin.password.length)
        return;
      this.isProcessing = true;

      //1. Trigger an async version check. NOTE: We do this check again just to make sure.
      this.$store.dispatch('checkForUpdate')
      .then(latestVersion => {
        if (latestVersion)
          throw new Error(`update-${latestVersion}`);
        
        return this.$store.dispatch('basicSignin', {
          username: this.formLogin.username,
          password: this.formLogin.password,
          uiMode: this.uiMode
        });
      })
      //2. Handle basic-signin promise.
      .then(() => {
        console.info(`Sign-in succeeded.`);
        // Redirect to main page.
        // this.$router.push(this.uiMode === 't'? '/tpos': '/mpos');
        this.$router.push(this.uiMode === 't'? '/pos/t': '/pos/m');
      })
      .catch(err => {
        console.error(`Basic sign-in failed`, JSON.stringify(err.response));
        //5. Handle version update.
        if (err.message.indexOf('update') == 0) {
          alert(`有更新的版本可用（${err.message.split('-')[1]}），請按「確定」以更新，並重新登入。`);
          //TODO: Force a page reload.
          window.location.reload();
        }
        //6. Handle incorrect credential error.
        else if (err.response.data){
          // alert('無法登入。請檢查您的帳號密碼，或者網路連線。');
          let errorMessage = this.$errorCodeToMessage(err.response.data.code);
          this.$alert(errorMessage || '請檢查您的帳號密碼，或者網路連線。', '登入失敗');
          this.isProcessing = false;
        }
      });
    },

    /// Extra button logic.
    forceReload() {
      window.location.reload();
    },
    showQrCode() {
      this.dialog.qrcode.url = process.env.VUE_APP_POS_URL;
      this.dialog.qrcode.show = true;
      // if (process.env.VUE_APP_FIREBASE_PROJECT_ID == 'qc-pos-production')
      //   this.dialog.qrcode.url = 'https://pos.quickclick.cc';
      // else
      //   this.dialog.qrcode.url = 'https://pos-staging.quickclick.cc';
    },
    showVersionInfo() {
      this.dialog.versionInfo.show = true;
      this.$store.commit('setVersionTipEnabled', false);
    },

    /// Cheat activation
    cheatsActivationClicker() {
      this.cheatsActivationClickCount++;
      // Renew a timeout of click count reset.
      if (this.cheatsActivationTimeout) clearTimeout(this.cheatsActivationTimeout);
      // Reset the click count if paused for too long.
      if (this.cheatsActivationClickCount < 8) {
        this.cheatsActivationTimeout = setTimeout(() => {
          this.cheatsActivationClickCount = 0;
        }, 1000);
      }
      else {
        this.dialog.cheats.show = true;
        this.cheatsActivationClickCount = 0;
      }
    },
    submitCheatSettings() {
      // console.warn('cheats submitted', JSON.stringify(this.dialog.cheats));
      this.$store.commit('setCheatSettings', this.dialog.cheats);
    }
  },

  data() {
    return {
      isProcessing: false,
      // useTablet: false,
      uiMode: this.$store.state.uiMode,

      formLogin: {
        valid: true,
        username: localStorage.getItem('qcpos-session-username') || '',
        password: localStorage.getItem('qcpos-session-password') || '',
        rememberMe: false
      },

      dialog: {
        qrcode: {
          show: false
        },
        versionInfo: {
          show: false
        },
        cheats: {
          show: false,          
          submitLogsEnabled: false,
          preSyncDisabled: false,

          picker: { 
            show: false
          },
          dates: [
            moment().startOf('day').format('YYYY-MM-DD'),
            moment().add(1, 'months').endOf('day').format('YYYY-MM-DD')
          ],
          dateRangeText: ''
        }
      },

      // About cheat activation.
      cheatsActivationClickCount: 0,
      cheatsActivationTimeout: undefined
    };
  },

  computed: {
    version() {
      console.log('Auth version changed', this.$store.state.version);
      return this.$store.state.version;
    },
    versionChanges() {
      return this.$store.state.versionChanges;
    },
    showVersionTip() {
      return this.$store.state.showVersionTip === 'true';
    },

    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    logoPath() {
      return process.env.VUE_APP_BRAND_LOGO;
    },
    bgColorCode() {
      return process.env.VUE_APP_THEME_COLOR_LIGHTEN;
    },

    // Cheats related
    dateRangeText() {
      return `${this.dialog.cheats.dates[0]} ~ ${this.dialog.cheats.dates[1]}`;
    }
  },

  watch: {
    version(v) {
      // console.warn(`Version change detected`, v);
      // if (process.env.PACKAGE_VERSION !== v) {
      //   alert(`有更新的版本可用（${v}），請按「確定」以更新，並重新登入。`);
      //   window.location.reload();
      // }
    }
  }
}
</script>

<style scoped>
.auth-container {
  background: #FFF3E0;
  height: 100%;
}
</style>

<style scoped>
/* Blinking effect */
.blink span.v-badge__badge {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>